import { Button, Col, Row, Tooltip, Typography } from "antd";
import React, { useCallback, useRef, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import InfiniteVirtualTable from "../../../../../components/custom/InfiniteVirtualTable";
import useCampaignApi from "../../../../../hooks/useCampaign";
import { ActivityDetailsModal } from "../../../../utils/CommonVessels";
import { fromNow, getHSFormattedDateString, getMomentDate } from "../../../../utils/dateUtils";
import {
  MAXIMUM_SUPPORTED_RECORDS_FOR_CAMPAIGN,
  getCampaignStatusFromStatusCode,
  getClassNameForCampaignStatus,
  getClassNameForCampaignType,
  getStatusTextFromTimeInMillis,
} from "../../../utils/campaignUtils";
import CampaignOverallMetricContainer from "./CampaignOverallMetricContainer";
import { formatPhoneNumber } from "../../../../utils/commonUtils";
import HButton from "../../../../../components/custom/input/button/Button";

const { Text } = Typography;
const NoDataComponent = ({ url, message, buttonName }) => {
  return (
    <>
      <div className="hs-text-muted mb-3">{message}</div>
      {buttonName && url ? (
        <Button className="hs-bg-violet text-white hs-border-10 px-5 med-button">
          <Link className="all-unset" to={url}>
            {buttonName}
          </Link>
        </Button>
      ) : (
        <></>
      )}
    </>
  );
};

function CampaignOverviewContent(props) {
  const { appProperties } = props;
  const location = useLocation();
  const navigate = useNavigate();
  const [data, setData] = useState();
  const { getAllCampaigns, loading } = useCampaignApi(appProperties);
  const [analytics, setAnalytics] = useState();
  const [userActivityData, setUserActivityData] = useState();
  const limit = 50;
  const handleRowClick = (record, event) => {
    // Don't navigate if clicking on interactive elements
    if (event && (
      event.target.tagName === 'A' || 
      event.target.closest('a') || 
      event.target.tagName === 'BUTTON' || 
      event.target.closest('button') ||
      event.target.classList.contains('actionIconsSprite') ||
      event.target.closest('.actionIconsSprite')
    )) {
      return;
    }
    
    let goto = "reports";
    if (record?.status?.status?.toLowerCase() === getCampaignStatusFromStatusCode(2)) {
      goto = "overview";
    }
    navigate(`/messaging/campaigns/list/${record.id}/${goto}${location.search}`);
  };

  const [activityDetailsModalOpen, setActivityDetailsModalOpen] = useState(false);

  const overviewTableColumns = [
    {
      title: "Title",
      dataIndex: "name",
      key: "name",
      align: "left",
      className: "overviewTableCells",
      width: 180,
      render: (text, item) => {
        // Determine destination based on campaign status
        let goto = "reports";
        if (item?.status?.status?.toLowerCase() === getCampaignStatusFromStatusCode(2)) {
          goto = "overview";
        }
        const campaignLink = `/messaging/campaigns/list/${item.id}/${goto}${location.search}`;
        
        // Handle campaign link click
        const handleLinkClick = (e) => {
          e.stopPropagation(); // Prevent row click from triggering
          
          // If cmd/ctrl+click, let the default behavior open in new tab
          // Otherwise prevent default and use navigate
          if (!e.metaKey && !e.ctrlKey) {
            e.preventDefault();
            navigate(campaignLink);
          }
          // If cmd/ctrl+click, browser will handle opening in new tab
        };
        
        return (
          <div className="w-100 d-flex align-items-center gap-2 cellInnerText">
            <a 
              href={campaignLink}
              onClick={handleLinkClick}
              className="cellInnerText"
              style={{ 
                color: '#1890ff', 
                textDecoration: 'none',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
                maxWidth: '130px',
                display: 'inline-block',
                cursor: 'pointer',
              }}
              title={text}
              onMouseEnter={(e) => e.target.style.textDecoration = 'underline'}
              onMouseLeave={(e) => e.target.style.textDecoration = 'none'}
            >
              {text}
            </a>
            <Tooltip title={item?.messageBody} className="hs-px-1">
              <div className="actionIconsSprite bg-white previewEyeIcon" />
            </Tooltip>
          </div>
        );
      },
    },
    {
      title: "Activity",
      dataIndex: "createdBy",
      key: "users",
      align: "left",
      className: "overviewTableCells",
      width: 100,
      render: (createdByUserObject, item) => {
        if (createdByUserObject) {
          createdByUserObject = JSON.parse(createdByUserObject);
        }
        let modifiedBy = item?.modifiedBy;
        if (modifiedBy) {
          modifiedBy = JSON.parse(modifiedBy);
        }
        const handleModal = (event) => {
          event.stopPropagation();
          setUserActivityData({
            createdBy: createdByUserObject,
            modifiedBy: modifiedBy,
            createdOn: item?.createdOn,
          });
          setActivityDetailsModalOpen(true);
        };
        // createdByUserObject = { ...createdByUserObject, iconUrl: null };
        return (
          <>
            <div className="cellInnerText d-flex align-items-center gap-2 justify-content-start" onClick={handleModal}>
              <Tooltip title={createdByUserObject?.name} className={`${createdByUserObject?.iconUrl ? "createdUser" : ""}`}>
                {createdByUserObject?.iconUrl ? (
                  <img alt="sender_url" src={createdByUserObject?.iconUrl} className="avatar-small-round" />
                ) : createdByUserObject?.name ? (
                  <div className="avatar-small-round bg-light d-flex justify-content-center align-items-center">
                    {createdByUserObject?.name?.slice(0, 1)}
                  </div>
                ) : (
                  <></>
                )}
              </Tooltip>
              {modifiedBy && modifiedBy[0]?.iconUrl && (
                <Tooltip title={modifiedBy[0]?.name}>
                  <img alt="sender_url" src={modifiedBy[0]?.iconUrl} className="avatar-small-round" />
                </Tooltip>
              )}
              {modifiedBy?.length > 1 && (
                <div className="avatar-small-round bg-light px-2 d-flex justify-content-center align-items-center">
                  +{modifiedBy?.length - 1}
                </div>
              )}
            </div>
          </>
        );
      },
    },
    {
      title: "Sender",
      dataIndex: "sender",
      key: "sender",
      align: "left",
      className: "overviewTableCells",
      width: 120,
      render: (text) => formatPhoneNumber(text),
    },
    {
      title: "Sent to",
      dataIndex: "sent_To",
      key: "sent_To",
      align: "left",
      className: "overviewTableCells",
      width: 300,
      render: (text) => {
        const displayText = text.length > 35 ? `${text.slice(0, 35)}...` : text;
        return (
          <Tooltip title={text}>
            <Text className="cellInnerText" ellipsis={true}>
              {displayText}
            </Text>
          </Tooltip>
        );
      },
    },
    {
      title: "Created Time",
      dataIndex: "createdOn",
      key: "createdOn",
      align: "left",
      className: "overviewTableCells",
      width: 130,
      render: (createdTime) => {
        const formattedTime = fromNow(getMomentDate(createdTime));
        return (
          <Tooltip title={getHSFormattedDateString(createdTime)}>
            <Text className="cellInnerText">{formattedTime}</Text>
          </Tooltip>
        );
      },
    },
    {
      title: "Type",
      dataIndex: "type",
      key: "type",
      align: "center",
      className: "overviewTableCells",
      width: 130,
      render: (type, item) => {
        const isPausedStatus = item?.status?.status === "PAUSED";
        const className = isPausedStatus ? "" : getClassNameForCampaignType(type);
        return (
          <div className="d-flex justify-content-center">
            <div className={`px-3 hs-w-fit hs-border-20 campaignBadge ${className}`}>{isPausedStatus ? "-" : type}</div>
          </div>
        );
      },
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      align: "center",
      className: "overviewTableCells",
      width: 170,
      render: (obj) => {
        const { sendDate, status } = obj;
        let statusText = status?.toLowerCase();
        let statusClassName = getClassNameForCampaignStatus(statusText);
        statusText = getStatusTextFromTimeInMillis(sendDate, statusText);
        return (
          <div className="d-flex align-items-center justify-content-center">
            <div className={`px-3 hs-w-fit hs-border-20 campaignBadge ${statusClassName}`}>{statusText}</div>
            {status?.toLowerCase() === "record_limit_exceeded" && (
              <Tooltip className="ms-1" title={MAXIMUM_SUPPORTED_RECORDS_FOR_CAMPAIGN.message}>
                <div className="actionIconsSprite failure-icon" />
              </Tooltip>
            )}
          </div>
        );
      },
    },
  ];

  const noDataComponentProps =
    appProperties?.installedApps?.length > 0
      ? {
          message: (
            <div className="d-flex flex-column align-items-center justify-content-center">
              <div className="hs-fs-16">Campaign messages will be listed here.</div>
              <div className="hs-fs-12">Campaigns efficiently convey messages to a broader audience, creating impactful engagement.</div>
            </div>
          ),
        }
      : {
          url: `/channels${location.search}`,
          message: (
            <div className="d-flex flex-column align-items-center justify-content-center">
              <div className="hs-fs-16">Campaign messages will be listed here.</div>
              <div className="hs-fs-12">Connect your channel and launch your first campaign.</div>
            </div>
          ),
          buttonName: "Add Channel",
        };

  let paginationRef = useRef({});
  
  const fetchDataAndUpdateState = useCallback(async () => {
    try {
      const res = await getAllCampaigns(limit, paginationRef.current);
      
      if (res?.campaigns?.length > 0) {
        // Generate unique keys using ID when available
        let newData = res?.campaigns
          ?.filter((item) => item.name)
          ?.map((ele, index) => ({
            ...ele,
            sent_To: ele?.csvFileName ? ele?.csvFileName : ele?.sentTo,
            key: `${ele.id || ''}_${index}`,
            status: ele,
          }));

        setData((prevData) => (prevData ? [...prevData, ...newData] : [...newData]));
        
        if (res?.totalCampaignsCount !== undefined || res?.totalSuccessMessages !== undefined) {
          setAnalytics({
            totalCampaignsCount: res?.totalCampaignsCount || 0,
            totalSuccessMessages: res?.totalSuccessMessages || 0,
          });
        }
        
        // Update pagination info if available, otherwise mark as complete
        if (res?.pagination) {
          paginationRef.current = res.pagination;
          return false; // More data available
        } else {
          return true; // No more data
        }
      } else {
        return true; // No data in response means we're done
      }
    } catch (error) {
      console.error("Error fetching campaign data:", error);
      return true; // Prevent infinite loading attempts on error
    }
  }, [getAllCampaigns, limit]);

  const handleClose = () => {
    setActivityDetailsModalOpen(false);
  };

  return (
    <div className="m-2 mt-lg-3 ms-lg-4 ps-lg-2">
      <div className="rounded h-100 pb-2">
        <div className="d-flex align-items-center justify-content-between bg-white p-3 rounded mb-3">
          <div className="d-flex">
            {/* Total Campaigns Metric */}
            <div className="campaign-metric-box me-5">
              <div className="d-flex align-items-center">
                <div className="staticIconsSprite campaignOverviewIcon totalCampaignsIcon me-3"></div>
                <div>
                  <div className="campaign-metric-title">Total Campaigns</div>
                  <div className="campaign-metric-value">{analytics?.totalCampaignsCount ?? 0}</div>
                </div>
              </div>
            </div>
            
            {/* Messages Sent Metric */}
            <div className="campaign-metric-box">
              <div className="d-flex align-items-center">
                <div className="staticIconsSprite campaignOverviewIcon messagesSentIcon me-3"></div>
                <div>
                  <div className="campaign-metric-title">Messages sent</div>
                  <div className="campaign-metric-value">{analytics?.totalSuccessMessages ?? 0}</div>
                </div>
              </div>
            </div>
          </div>
          
          {/* Create Button */}
          <div>
            <Link className="all-unset" to={"/campaign" + location.search}>
              <button className="campaign-create-button">
                <div className="actionIconsSprite sendRocketWhite me-2"></div>
                Create
              </button>
            </Link>
          </div>
        </div>
      </div>
      <Row>
        <div className="rounded-top h-100 w-100 bg-white">
          <ActivityDetailsModal data={userActivityData} handleClose={handleClose} open={activityDetailsModalOpen} />
          <InfiniteVirtualTable
            noDataOverlayIcon={<div className="staticIconsSprite rocketWithAirIcon"></div>}
            noDataComponent={<NoDataComponent {...noDataComponentProps} />}
            fetchFn={fetchDataAndUpdateState}
            handleRowClick={handleRowClick}
            appProperties={appProperties}
            loading={loading}
            columns={overviewTableColumns}
            data={data}
          />
        </div>
      </Row>
    </div>
  );
}

export default CampaignOverviewContent;
