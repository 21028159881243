import React, { useCallback, useContext, useEffect, useState } from "react";

import { Layout, message } from "antd";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import "../../assets/css/commons.css";
import "../../assets/css/contentBar.css";
import "../../assets/css/services/services.css";
import { changeModalState, ModalState, urlParams } from "../../constants/AppConstants";
import { openServiceNotAuthorizedModal } from "../../pages/utils/commonUtils";
import { InvalidModal, LicenseInvalidModal } from "../../pages/utils/CommonVessels";
import Dialpad from "../../voice/components/Dialpad";
import { getCurrentFeature, getCurrentPlanName, useVoiceIntegId } from "../../voice/utils/VoiceUtils";
import { AppContext } from "./../../context/AppContext";
import { useVoiceContext } from "../../voice/context/VoiceContext";
import { useVoiceNumbers } from "../../voice/hooks/useVoiceNumbers";

const { Content } = Layout;

function getDaysDifference(startDay, endDay) {
  var millisBetween = startDay.getTime() - endDay.getTime();
  var days = millisBetween / (1000 * 3600 * 24);
  return Math.round(Math.abs(days));
}

function Contentbar(props) {
  const location = useLocation();
  const path = location.pathname;
  const [voiceSavedNumbers, setVoiceSavedNumbers] = useState([]);
  const { getVoiceSavedNumbers } = useVoiceNumbers();
  const [callProperties, setCallProperties] = useVoiceContext();
  const navigate = useNavigate();
  const [activateLicense, setActivateLicense] = useState(false);

  const [messageApi, contextHolder] = message.useMessage();
  const [loadDialPad, setLoadDialPad] = useState(true);
  let onBoardingPage = urlParams.get("onBoardingPage");

  const [onBoarding, setOnBoarding] = useState(onBoardingPage === "true");
  const [leftAuthorizeModal, setLeftAuthorizeModal] = useState(false);
  const [allPageContainer, setAllPageContainer] = useState("hs-bg-off-white overflow-auto mainPage");
  const [messageLoaded, setMessageLoaded] = useState(false);
  const [appPropertiesLength, setAppPropertiesLength] = useState();
  const [appProperties] = useContext(AppContext);
  let locationHref = window.location.href;
  let fullView = urlParams.get("fullView");
  let sideBar = urlParams.get("sideBar");
  const topBar = urlParams.get("topBar");

  useEffect(() => {
    const pathSegments = location.pathname.split("/");
    let headerValue = "";

    if (pathSegments.length > 2) {
      if (pathSegments.includes("settings") || pathSegments.includes("voice") || pathSegments.includes("messaging")) {
        headerValue = pathSegments[2];
      } else {
        headerValue = pathSegments[1];
      }
    } else {
      headerValue = pathSegments[1];
    }

    props.setHeaderTitle(headerValue);

    if (fullView === "false" && sideBar === "false") {
      setAllPageContainer("hs-bg-off-white overflow-auto");
    }
  }, [fullView, location.pathname, props, sideBar]);

  const showLicenseBanner = useCallback(
    (content, messageType) => {
      messageApi.open({
        type: messageType,
        content: content,
        duration: 5,
      });
    },
    [messageApi]
  );

  useEffect(() => {
    if (location.pathname.startsWith("/campaigns")) {
      const newPath = `/messaging${location.pathname}` + location.search;
      navigate(newPath, { replace: true });
    } else if (location.pathname.startsWith("/automation")) {
      const automationPath = (location.pathname = `/messaging${location.pathname}` + location.search);
      navigate(automationPath, { replace: true });
    }
  }, [location, navigate]);

  useEffect(() => {
    if (appProperties !== null && appProperties !== undefined) {
      if (appProperties.licenseObj != null) {
        let licenseInfo = appProperties.licenseObj.licenseDetails;
        if (licenseInfo !== null && licenseInfo !== undefined) {
          var licenseStatus = licenseInfo.licenseStatus;
          console.log(":::::::  inside - content bar - licenseObj.licenseDetails :::: 4444 ::::", licenseStatus);
          if ((licenseStatus === 4 || licenseStatus === 5) && !messageLoaded) {
            var currentDate = new Date();
            var createdDate = new Date(licenseInfo.createdTime);
            var trialExpiryDate = "";
            if (licenseInfo.trialDays !== null) {
              trialExpiryDate = createdDate.setDate(createdDate.getDate() + licenseInfo.trialDays);
            } else {
              trialExpiryDate = createdDate.setDate(createdDate.getDate() + 3);
            }
            var trialExpiryDateObj = new Date(trialExpiryDate);
            // var trialExpiryISODate = trialExpiryDateObj.toLocaleDateString();

            var daysDifference = getDaysDifference(trialExpiryDateObj, currentDate);
            let messageType = "warning";
            let messageContent = "Trial expires in " + daysDifference + " days";
            let isTrialStarted = localStorage.getItem("Trial");
            if (daysDifference === 3) {
              if (isTrialStarted === null) {
                messageContent = "Trial activated successfully. Enjoy your 3 days free trial.";
                messageType = "success";
                localStorage.setItem("Trial", true);
                showLicenseBanner(messageContent, messageType);
              }
              setMessageLoaded(true);
            }
          } else if (licenseStatus > 10 && locationHref.indexOf("/content") === -1 && locationHref.indexOf("account/helloSend/active") === -1) {
            setActivateLicense(true);
            changeModalState(true);
          }
        }
      }
      if (appProperties.authorizeObj) {
        let authObj = appProperties.authorizeObj;
        let leftAuth = authObj?.left?.auth?.authorized;
        const avoidPagesForAuth = ["/channels", "/channels/textsms", `/channels/textsms/${authObj?.right?.service_name}`];
        function shouldAvoidAuth(path) {
          return avoidPagesForAuth.some((avoidPath) => {
            if (path === avoidPath) return true;
            if (path.startsWith(avoidPath + "/")) {
              const remainingPath = path.slice(avoidPath.length + 1);
              return !remainingPath.includes("/");
            }
            return false;
          });
        }

        if (
          leftAuth === false &&
          locationHref.indexOf("account/helloSend/active") === -1 &&
          locationHref.indexOf("/authorize") === -1 &&
          locationHref.indexOf("/teams/landing") === -1
        ) {
          setLeftAuthorizeModal(true);
          changeModalState(true);
        } else {
          setLeftAuthorizeModal(false);
          changeModalState(false);
        }
        let rightAuth = authObj?.right?.auth?.authorized;
        let rightServiceName = authObj?.right?.service_name;
        if (
          rightAuth === false &&
          locationHref.indexOf("/account/" + appProperties.righServiceName + "/authorize") === -1 &&
          locationHref.indexOf("/account") === -1 &&
          locationHref.indexOf("/authorize") === -1 &&
          locationHref.indexOf("/twilio_voice") === -1 &&
          locationHref.indexOf("/guide") === -1 &&
          licenseStatus < 11 &&
          rightServiceName !== "chatgpt" &&
          !shouldAvoidAuth(location.pathname)
        ) {
          if (!ModalState && !authObj?.right?.auth?.ignoreAuthorizedCheck) {
            openServiceNotAuthorizedModal(
              { service_name: rightServiceName, service_display_name: authObj?.right?.service_display_name },
              appProperties
            );
          }
          changeModalState(true);
        } else {
          changeModalState(false);
        }
      } else if (licenseStatus > 10 && locationHref.indexOf("/content") === -1 && locationHref.indexOf("/account/helloSend/phone") === -1) {
        setActivateLicense(true);
        changeModalState(true);
      }
      console.log("leftAuthorizeModal>>>>", leftAuthorizeModal);
    }
  }, [
    appProperties.licenseObj,
    appProperties.authorizeObj,
    appProperties.osyncId,
    location.pathname,
    appProperties,
    locationHref,
    messageLoaded,
    showLicenseBanner,
    leftAuthorizeModal,
  ]);

  useEffect(() => {
    setAppPropertiesLength(Object.keys(appProperties).length);
  }, [appProperties]);
  const voiceIntegId = useVoiceIntegId(appProperties);
  useEffect(() => {
    const currentPlanName = getCurrentFeature(appProperties);
    if (voiceIntegId && currentPlanName !== "Message" && callProperties?.savedNumbers.length === 0) {
      getVoiceSavedNumbers("all")
        .then((response) => {
          if (response) {
            setVoiceSavedNumbers(response);
          }
        })
        .catch((err) => {
          console.log("Error fetching SavedNumbers:", err);
        });
    }
  }, [voiceIntegId]);

  const [shrinkView] = useState(urlParams.get("fullView") === "false");

  return (
    <>
      {contextHolder}
      {activateLicense ? <LicenseInvalidModal /> : null}
      {leftAuthorizeModal === true && location.pathname !== "/zoho-crm/landing" ? <InvalidModal authorizeModal={leftAuthorizeModal} /> : null}

      <Content>
        <div
          style={{
            height:
              (sideBar === "false" && topBar === "false") || location.pathname.includes("/landing") || path.includes("voice/dialpad")
                ? "100dvh"
                : "calc(100dvh - 60px)",
          }}
          className={allPageContainer}
        >
          {loadDialPad &&
          Array.isArray(voiceSavedNumbers) &&
          (voiceSavedNumbers.length > 0 || callProperties?.savedNumbers?.length > 0) &&
          !path.includes("sendsms") &&
          !path.includes("voice/dialpad") &&
          !path.includes("/landing") &&
          !(path.includes("/campaign") && shrinkView) &&
          getCurrentPlanName(appProperties?.licenseObj?.licenseDetails?.planName) !== "Message" ? (
            <Dialpad />
          ) : null}
          {appPropertiesLength > 0 && <Outlet />}
        </div>
      </Content>
    </>
  );
}

export default Contentbar;
