import { Device } from "@twilio/voice-sdk";
import { Avatar, Button, Col, Dropdown, Form, message, Row, Skeleton, Space } from "antd";
import React, { useContext, useEffect, useState } from "react";
import { AgentStatusColors, COMPANY_NAME, urlParams } from "../../constants/AppConstants";
import { AppContext } from "../../context/AppContext";
import useHttp from "../../hooks/useHttp";
import { requestBrowserNotificationPermission, showIncomingCallNotification } from "../../pages/utils/CommonNotifications";
import { checkWhetherServiceIsAuthorized } from "../../pages/utils/commonUtils";
import "../assets/css/dialpad.css";
import { useVoiceContext } from "../context/VoiceContext";
import { useVoiceAgentStatus } from "../hooks/useVoiceAgentStatus";
import { useVoiceNumbers } from "../hooks/useVoiceNumbers";
import { getCurrentFeature, voiceAgentStatus } from "../utils/VoiceUtils";
import { initPusher, UpgradeAlertPopup } from "./../../pages/utils/CommonVessels";
import AgentStatusSelect from "./call-screens/AgentStatusSelect";
import CallAnswered from "./call-screens/CallAnswered";
import CallRecordSearch from "./call-screens/CallRecordSearch";
import CallSaveAndClose from "./call-screens/CallSaveAndClose";
import CreateLeadOrContact from "./call-screens/CreateLeadOrContact";
import IncomingPopUp from "./IncomingPopUp";
import NoAccess from "./info-component/NoAccess";
message.config({
  maxCount: 1,
  top: 50,
});
const Dialpad = (props) => {
  const { getVoiceSavedNumbers } = useVoiceNumbers();
  let { showDialPad } = props;
  const [callNotesForm] = Form.useForm();
  const [searchValue, setSearchValue] = useState();
  const [fetchCallLogs, setFetchCallLogs] = useState(false);
  const [callEnded, setCallEnded] = useState(false);
  const [openCreateLeadOrContactForm, setOpenCreateLeadOrContactForm] = useState(false);
  const [twilioDevice, setTwilioDevice] = useState(null);
  const [twilioDeviceIsReady, setTwilioDeviceIsReady] = useState(false);
  const [callConnection, setCallConnection] = useState({});
  const [isStatic, setIsStatic] = useState(false);
  const [callParams, setCallParams] = useState();
  const [createModuleName, setCreateModuleName] = useState("");
  const [module, setModule] = useState("");
  const [answered, setAnswered] = useState(false);
  const [openCallDetails, setOpenCallDetails] = useState(false);
  const [callDetails, setCallDetails] = useState();
  const { fetchData } = useHttp();
  const [appProperties] = useContext(AppContext);
  const [phoneNumbers, setPhoneNumbers] = useState([]);
  const [callType, setCallType] = useState(null);
  const [showOfflineMessageWhileInit, setShowOfflineMessageWhileInit] = useState(false);
  const [orgNumber, setOrgNumber] = useState("");
  const [customerNumber, setCustomerNumber] = useState("");
  const [isMakeTestCall, setIsMakeTestCall] = useState(false);
  const [incomingCallTo, setIncomingCallTo] = useState();
  const [upgradeAlertPopup, setUpgradeAlertPopup] = useState(false);
  const [lastName, setLastName] = useState("");
  const [avatar, setAvatar] = useState();
  const [recordId, setRecordId] = useState("");
  const [recordLink, setRecordLink] = useState("");
  const [callNotes, setCallNotes] = useState("");
  const [totalCallDuration, setTotalCallDuration] = useState(0);
  const [isCallAnswered, setIsCallAnswered] = useState(false);
  const [callSid, setCallSid] = useState("");
  const [voiceIntegId, setVoiceIntegId] = useState("");
  const [isVoiceUser, setIsVoiceUser] = useState(false);
  const [restrictAccess, setRestrictAccess] = useState(false);
  const [saveAndCloseClicked, setSaveAndCloseClicked] = useState(false);
  const [callProperties, setCallProperties] = useVoiceContext();
  const [pusherChannel, setPusherChannel] = useState();

  const { getCurrentVoiceAgentStatus } = useVoiceAgentStatus();

  let leftServiceId = appProperties?.leftServiceId;
  let path = window.location.pathname;

  const redirectToCallHistoryPage = () => {
    appProperties?.controller?.getUrlParamsForRedirection(true, appProperties).then(function (params) {
      var redirectLink = "/voice/history?" + params;
      window.open(redirectLink);
    });
  };

  const avatarDropDownMenuItems = [
    {
      key: "1",
      label: (
        <>
          <div className="d-flex justify-content-between w-100">
            <div className="w-50 d-flex flex-row align-items-center cursor-default">
              <div className="call-action-icons from-number-call-icon-dialpad-avatar-menu"></div>
              <div>Phone</div>
            </div>
            <div className="w-50 d-flex flex-row" onClick={(e) => e.stopPropagation()}>
              <AgentStatusSelect
                classNameProps={"dialPadSelectors"}
                expandIcon={"agentStatusDropDownIcon"}
                setShowOfflineMessageWhileInit={setShowOfflineMessageWhileInit}
                restrictAccess={restrictAccess}
              />
            </div>
          </div>
        </>
      ),
    },
    {
      key: "2",
      label: <div onClick={redirectToCallHistoryPage}>Go to {COMPANY_NAME}</div>,
    },
  ];

  const resetCallPopup = () => {
    setCallType("");
    setRecordId("");
    setRecordLink("");
    setCallNotes("");
    callNotesForm.setFieldValue("callDescription", "");
    setModule("");
    setLastName("");
    setAvatar("");
    setCallSid("");
    setCustomerNumber("");
    setTotalCallDuration(0);
    setCallEnded(false);
    // if (twilioDevice) {
    //   console.log("Twilio-Event ::: deviceInstance >>>>>>>> destroy 222 >>>>>");
    //   twilioDevice.destroy();
    // }
    setCallConnection({});

    setCallProperties((prev) => {
      console.log("Before update:", prev);
      const updatedState = {
        ...prev,
        callerNumber: null,
        callRecordId: null,
        callRecordModule: null,
        callType: null,
        callStatus: "idle",
      };
      console.log("After update:", updatedState);
      return updatedState;
    });
  };

  useEffect(() => {
    const featureName = getCurrentFeature(appProperties);
    if (featureName === "Message") {
      setUpgradeAlertPopup(true);
    }
  }, [appProperties]);

  const handleDialPadClick = () => {
    setCallProperties((prev) => ({
      ...prev,
      integId: voiceIntegId,
      isDialPadVisible: true,
    }));
  };

  const handleLastNameClick = () => {
    appProperties?.controller?.openRecord(callProperties?.loadedSDK, recordId, module);
  };

  const initPusherCallForThisUser = (e) => {
    if (!pusherChannel && appProperties?.currentUser?.osyncUserId) {
      initPusher(fetchData, appProperties, appProperties?.currentUser?.osyncUserId).then((res) => {
        setPusherChannel(res);
      });
    }
  };

  useEffect(() => {
    if (appProperties?.installedApps) {
      const voiceIntegrations = appProperties?.installedApps?.filter((integration) => integration.right.serviceType === "VOICE");
      if (voiceIntegrations?.[0]?.integProps?.integId) {
        setVoiceIntegId(voiceIntegrations?.[0]?.integProps?.integId);
      }
    }
  }, [appProperties?.installedApps]);
  useEffect(() => {
    if (pusherChannel) {
      pusherChannel?.channel?.bind("NOTIFY_VOICE_CLIENTS", (data) => {
        console.log("dedec >> DATA-RECEIVED >>>>>", data);
        if (data) {
          try {
            let response = JSON.parse(data?.data);

            if (response) {
              let contactInfo = response;
              if (typeof contactInfo === "string") {
                contactInfo = JSON.parse(contactInfo);
              }
              console.log("dedec_pusherChannel >> contactInfo >>>>>", contactInfo);
              if (contactInfo) {
                if (contactInfo.lastName === undefined) {
                  contactInfo = response?.[0];
                }
              }
              if (contactInfo) {
                setLastName(contactInfo?.lastName);
                setRecordId(contactInfo?.id);
                setModule(contactInfo.module);
                setAvatar(contactInfo?.avatar);
                setRecordLink(contactInfo?.recordLink);
              } else {
                setLastName("Unknown caller");
                setRecordId("");
                setModule(null);
                setAvatar(null);
                setRecordLink(null);
              }
            }
          } catch (e) {
            console.error("ERROR::::", e);
          }
        }
      });
    }
  }, [pusherChannel]);

  useEffect(() => {
    setCallProperties((prev) => ({
      ...prev,
      integId: voiceIntegId,
      isDialPadVisible: showDialPad ?? "",
    }));
  }, [showDialPad]);

  useEffect(() => {
    if (callProperties?.savedNumbers && callProperties?.savedNumbers.length > 0) {
      const savedNumbers = callProperties?.savedNumbers?.map((item) => ({
        value: item.phoneNumber,
        label: item.phoneNumber,
      }));
      setPhoneNumbers(savedNumbers);
    } else {
      setPhoneNumbers([]);
    }
  }, [callProperties.savedNumbers]);
  console.log("callProperties", callProperties);
  useEffect(() => {
    if (callProperties?.callRecordId) {
      setRecordId(callProperties?.callRecordId);
    }
  }, [callProperties?.callRecordId]);
  useEffect(() => {
    if (isVoiceUser !== undefined && !isVoiceUser) {
      console.log("Debug--isVOiceUser", isVoiceUser);
      setRestrictAccess(true);
      // setCallProperties((prev) => ({
      //   ...prev,
      //   disableAgentStatusChange: true,
      // }))
    } else {
      setRestrictAccess(false);
    }
  }, [isVoiceUser]);
  useEffect(() => {
    if (callProperties?.callerNumber) {
      setCustomerNumber(callProperties?.callerNumber);
    }
  }, [callProperties?.callerNumber]);

  useEffect(() => {
    if (callProperties?.callRecordModule) {
      setModule(callProperties?.callRecordModule);
    }
  }, [callProperties?.callRecordModule]);

  useEffect(() => {
    if (
      callProperties?.agentStatusValue &&
      (callProperties?.agentStatusValue === voiceAgentStatus.OFFLINE || callProperties?.agentStatusValue === voiceAgentStatus.BUSY)
    ) {
      setShowOfflineMessageWhileInit(true);
    } else {
      setShowOfflineMessageWhileInit(false);
    }
  }, [callProperties?.agentStatusValue]);

  useEffect(() => {
    setLastName(callProperties?.callMockData?.lastName);
    setRecordId(callProperties?.callMockData?.recordId);
    setRecordLink(callProperties?.callMockData?.recordLink);
    setModule(callProperties?.callMockData?.module);
    setAvatar(callProperties?.callMockData?.avatar);
    setCustomerNumber(callProperties?.callMockData?.phone);
    setIncomingCallTo(callProperties?.callMockData?.incomingCallTo);
  }, [callProperties?.callMockData]);

  useEffect(() => {
    const fetchSavedNumbers = async () => {
      try {
        if (!voiceIntegId) return;
        const responseData = await getVoiceSavedNumbers("private");
        const numbers = responseData?.map((item) => ({
          value: item.phoneNumber,
          label: item.phoneNumber,
        }));
        setPhoneNumbers(numbers);
        setCallProperties((prev) => ({
          ...prev,
          savedNumbers: responseData,
        }));

        if (Array.isArray(responseData) && responseData.length > 0) {
          setOrgNumber(responseData[0].phoneNumber);
        }
      } catch (error) {
        console.error("Error fetching saved numbers:", error);
      }
    };

    if (path === "/voice/dialpad") {
      setIsStatic(true);
    }

    fetchSavedNumbers();
  }, [appProperties, voiceIntegId, fetchData]);

  useEffect(() => {
    requestBrowserNotificationPermission();
  }, []);

  const endCall = () => {
    setCallEnded(true);
    setCallNotes(callNotesForm.getFieldValue("callDescription"));
    try {
      if (twilioDevice && typeof twilioDevice.disconnectAll === "function") {
        twilioDevice.disconnectAll();
        handleStatusChange(voiceAgentStatus.AFTER_CALL_PROCESS);
      }
      if (callConnection) {
        callConnection.disconnect();
      }
    } catch (error) {
      console.error("Error disconnecting call:", error);
    }
  };

  const hangUpCall = () => {
    resetCallPopup();
    try {
      if (twilioDevice && typeof twilioDevice.disconnectAll === "function") {
        twilioDevice.disconnectAll();
      }
      if (callConnection) {
        callConnection.disconnect();
      }
    } catch (error) {}
  };
  useEffect(() => {
    if (appProperties.userId && appProperties.userId !== undefined && voiceIntegId) {
      getCurrentVoiceAgentStatus().then((response) => {
        const isVoiceUser = response?.isVoiceUser;
        setIsVoiceUser(isVoiceUser === "true");
        if (isVoiceUser) {
          const statusValue = response?.agentStatus?.voiceAgentStatus;
          if (statusValue && statusValue !== undefined) {
            if (
              statusValue === voiceAgentStatus.AFTER_CALL_PROCESS ||
              statusValue === voiceAgentStatus.BUSY ||
              statusValue === voiceAgentStatus.ON_CALL
            ) {
              setCallProperties((prev) => ({
                ...prev,
                agentStatusValue: voiceAgentStatus.BUSY,
              }));
            } else {
              setCallProperties((prev) => ({
                ...prev,
                agentStatusValue: statusValue,
              }));
            }
            const callOnboarding = localStorage.getItem("callOnboarding");

            // if (
            //   callProperties?.serviceInitialized &&
            //   statusValue === voiceAgentStatus.OFFLINE &&
            //   callOnboarding === "completed" &&
            //   path === "/voice/dialpad"
            // ) {
            //   if (callProperties?.loadedSDK) {
            //     // appProperties.controller.showCallNotification(callProperties?.loadedSDK, "offline");
            //     localStorage.setItem("callOnboarding", "completed");
            //     setCallProperties((prev) => ({
            //       ...prev,
            //       serviceInitialized: false,
            //     }));
            //   }
            // } else
            if (isVoiceUser && !callOnboarding && path === "/voice/dialpad" && voiceIntegId) {
              handleStatusChange(voiceAgentStatus.ONLINE_WEB).then((res) => {
                if (res) {
                  localStorage.setItem("callOnboarding", "completed");
                  appProperties.controller.showCallNotification(callProperties?.loadedSDK, "offline");
                  setCallProperties((prev) => ({
                    ...prev,
                    serviceInitialized: false,
                  }));
                }
              });
            } else {
              if (statusValue && (statusValue === voiceAgentStatus.OFFLINE || statusValue === voiceAgentStatus.BUSY)) {
                setShowOfflineMessageWhileInit(true);
              }
            }
          }
        }
      });
    }
  }, [appProperties.userId, callProperties?.loadedSDK, callProperties?.serviceInitialized, path, voiceIntegId]);

  useEffect(() => {
    if (callProperties?.agentStatusValue !== voiceAgentStatus.ONLINE_WEB) {
      setCallProperties((prev) => ({
        ...prev,
        focusToNumberField: "hidden",
      }));
    }
  }, [callProperties?.agentStatusValue]);

  useEffect(() => {
    console.log("CTC :::isDialPadVisible:::: callProperties?.isDialPadVisible ::::: ", callProperties?.isDialPadVisible);
    console.log("CTC :::isDialPadVisible:::: callProperties?.loadedSDK ::::: ", callProperties?.loadedSDK);

    if (callProperties?.isDialPadVisible) {
      appProperties?.controller?.showCallNotification(callProperties?.loadedSDK, "offline");
    } else {
      appProperties?.controller?.hideCallNotification(callProperties?.loadedSDK);
    }
  }, [callProperties?.isDialPadVisible]);

  useEffect(() => {
    console.log("CTC :::isOnlineAndReady:::: callProperties?.callStatus ::::: ", callProperties?.callStatus);
    console.log("CTC :::isOnlineAndReady:::: callProperties?.agentStatusValue ::::: ", callProperties?.agentStatusValue);
    console.log("CTC :::isOnlineAndReady:::: callType ::::: ", callType);
    console.log("CTC :::isOnlineAndReady:::: customerNumber ::::: ", customerNumber);
    const webSource = urlParams.get("source");
    let isCallingDevice = true;
    if (webSource && (webSource === "DM" || webSource === "webTab")) {
      isCallingDevice = false;
    }
    const isOnlineAndReady =
      callProperties?.agentStatusValue === voiceAgentStatus.ONLINE_WEB &&
      callProperties?.callStatus === "idle" &&
      callType === "outbound" &&
      customerNumber;

    console.log("CTC :::isOnlineAndReady:::: isOnlineAndReady ::::: ", isOnlineAndReady);

    if (isOnlineAndReady && isCallingDevice) {
      console.log("CTC :::isOnlineAndReady:::: phoneNumbers.length ::::: ", phoneNumbers.length);
      if (phoneNumbers.length === 1) {
        setCallProperties((prev) => ({
          ...prev,
          callStatus: "ringing",
          recordId: recordId,
        }));
        makeOutboundCall();
      } else {
        setCallProperties((prev) => ({ ...prev, callStatus: "idle" }));
      }
    }
  }, [
    customerNumber,
    callProperties.callerNumber,
    callProperties.isDialPadVisible,
    callProperties?.callStatus,
    callType,
    phoneNumbers.length,
    setCallProperties,
    recordId,
  ]);

  useEffect(() => {
    if (callProperties?.callType) {
      setCallType(callProperties?.callType);
    }
  }, [callProperties.callType]);

  const registerTwilio = () => {
    return new Promise((resolve, reject) => {
      try {
        console.log("registerTwilio ------ voiceIntegId", voiceIntegId);

        const isServiceAuthorized = checkWhetherServiceIsAuthorized(appProperties, voiceIntegId, null, false);

        console.log("registerTwilio ------ isServiceAuthorized", isServiceAuthorized);

        if (!voiceIntegId || !isServiceAuthorized) {
          // reject(new Error("Service not authorized or missing voice integration ID"));
          return;
        }

        fetchData(`voice/${voiceIntegId}/register`, "POST", {}, appProperties)
          .then((response) => {
            console.log("registerTwilio ------ response", response);

            const parsedResponse = typeof response === "string" ? JSON.parse(response) : response;

            const token = parsedResponse?.data?.token;

            if (!token || typeof token !== "string") {
              handleStatusChange(voiceAgentStatus.OFFLINE);
              throw new Error("Invalid or missing token");
            }

            // Update state
            setCallProperties((prev) => ({
              ...prev,
              twilioCapToken: token,
            }));

            initPusherCallForThisUser();

            resolve(token);
          })
          .catch((error) => {
            console.error("Twilio registration failed:", error);
            handleStatusChange(voiceAgentStatus.OFFLINE);
            reject(error);
          });
      } catch (error) {
        console.error("Unexpected error in registerTwilio:", error);
        reject(error);
      }
    });
  };

  useEffect(() => {
    let deviceInstance = null;

    const setupTwilioDevice = async () => {
      try {
        if (!callProperties?.twilioCapToken) return;

        // Create Device Instance
        deviceInstance = new Device(callProperties.twilioCapToken, {
          // Your device options
        });

        setTwilioDevice(deviceInstance);

        // Device Registration Handler
        const handleDeviceRegistration = () => {
          console.log("Twilio-Event ::: Device ready to receive calls >>>>>>");
          setTwilioDeviceIsReady(true);
        };

        // Incoming Call Handler
        const handleIncomingCall = (call) => {
          console.log("Twilio-Event ::: Incoming Call >>>>>>", call.status(), "::::: call >>>", call);
          setCallConnection(call);
          setOpenCallDetails(false);

          const from = call.parameters.From;
          showIncomingCallNotification("Incoming Call", `Call from ${from}`);

          // Call Event Listeners
          setupCallEventListeners(call);
        };

        // Error Handling
        const handleDeviceError = async (error) => {
          console.log("Twilio-Event ::: Call Error>>>>>>", error);
          message.error("Something went wrong while connecting to Twilio. Check your network connection and try again.");
          setTwilioDeviceIsReady(false);
          handleStatusChange(voiceAgentStatus.OFFLINE);
          if (error?.message.includes("AccessTokenExpired")) {
            await handleTokenExpiration();
          }
        };

        // Token Expiration Handler
        const handleTokenExpiration = async (error) => {
          try {
            console.log("Twilio-Event ::: Token will expire. Re-registering...", error);
            await registerTwilio();
          } catch (refreshError) {
            console.error("Token refresh failed", refreshError);
          }
        };

        // Event Listeners
        deviceInstance.on("registered", handleDeviceRegistration);
        deviceInstance.on("incoming", handleIncomingCall);
        deviceInstance.on("error", handleDeviceError);
        deviceInstance.on("tokenWillExpire", handleTokenExpiration);

        // Register Device
        deviceInstance
          .register()
          .then(() => {
            console.log("Twilio-Event ::: Device successfully registered");
          })
          .catch((error) => {
            // handleDeviceError(error);
          });
      } catch (error) {
        console.error("Twilio device setup error:", error);
      }
    };

    // Call Setup Function
    const setupCallEventListeners = (call) => {
      call.on("accept", () => {
        console.log("Twilio-Event ::: Call accepted successfully", call.status(), "::::: call >>>", call);
        setIsCallAnswered(true);
        setCallProperties((prev) => ({
          ...prev,
          disableAgentStatusChange: true,
        }));
      });

      call.on("disconnect", (e) => {
        console.log("Twilio-Event ::: Call has ended", call.status(), "::::: call >>>", call, " ::: ", e);
        setCallConnection({});
        setCallProperties((prev) => ({
          ...prev,
          disableAgentStatusChange: false,
        }));
        setCallEnded(true);
      });

      call.on("cancel", () => {
        console.log("Twilio-Event ::: The incoming call was canceled.", call.status(), "::::: call >>>", call);
        handleCallCancellation(call);
        setCallEnded(true);
        resetCallPopup();
      });
    };

    // Call Cancellation Handler
    const handleCallCancellation = (call) => {
      if (call.status() === "closed" || (!isCallAnswered && callProperties?.loadedSDK)) {
        appProperties?.controller?.disableFocusMode(callProperties.loadedSDK);
        appProperties?.controller?.hideCallNotification(callProperties.loadedSDK);
        if (!openCreateLeadOrContactForm) {
          setCallProperties((prev) => ({
            ...prev,
            disableAgentStatusChange: false,
            isDialPadVisible: false,
            callStatus: "idle",
            incomingCall: false,
          }));
          setCustomerNumber("");
        } else
          setCallProperties((prev) => ({
            ...prev,
            disableAgentStatusChange: false,
            callStatus: "idle",
            incomingCall: false,
          }));
      }
    };

    // Trigger Device Setup
    setupTwilioDevice();

    // Cleanup Function
    return () => {
      if (deviceInstance) {
        console.log("Twilio-Event ::: deviceInstance >>>>>>>> destroy 111 >>>>>");
        deviceInstance.destroy();
      }
    };
  }, [callProperties?.twilioCapToken]);

  useEffect(() => {
    if (callConnection && Object.keys(callConnection).length > 0) {
      setCallType("inbound");

      let phone = callConnection?.parameters?.From;
      let incomingCallTo = callConnection?.parameters?.toNumber;
      setCallProperties((prev) => ({
        ...prev,
        isDialPadVisible: true,
        disableAgentStatusChange: true,
        incomingCall: true,
      }));
      setCustomerNumber(phone);
      if (incomingCallTo) {
        setIncomingCallTo(incomingCallTo);
      }
      let customParameters = callConnection?.customParameters;

      if (callProperties?.loadedSDK) {
        appProperties.controller.showCallNotification(callProperties?.loadedSDK, "inbound");
      }

      const dataArray = Array.from(customParameters);

      for (var i = 0; i < dataArray.length; i++) {
        let paramsArray = dataArray[i];

        let paramKey = paramsArray[0];
        let paramValue = paramsArray[1];
        if (paramKey === "name") {
          setLastName(paramValue);
        } else if (paramKey === "module") {
          setModule(paramValue);
        } else if (paramKey === "recordId") {
          setRecordId(paramValue + "FFF");
        } else if (paramKey === "avatar") {
          setAvatar(paramValue);
        } else if (paramKey === "incomingCallTo") {
          setIncomingCallTo(paramValue);
        } else if (paramKey === "callSid") {
          setCallSid(paramValue);
        }
      }
    }
  }, [callConnection]);

  useEffect(() => {
    if (callConnection?._status === "closed") {
      setCallProperties((prev) => ({
        ...prev,
        disableAgentStatusChange: false,
        isDialPadVisible: false,
        callStatus: "idle",
        callType: "",
        incomingCall: false,
      }));
      resetCallPopup();
      // setCallConnection({})
      setCustomerNumber("");
    }
  }, [callConnection?._status]);

  useEffect(() => {
    console.log("CTC :::registerTwilioDevice:::: twilioDevice ::::: ", !twilioDevice);
    console.log("CTC :::registerTwilioDevice:::: callProperties?.agentStatusValue ::::: ", callProperties?.agentStatusValue);
    console.log("CTC :::registerTwilioDevice:::: twilioDeviceIsReady ::::: ", !twilioDeviceIsReady);

    if (!twilioDevice && callProperties?.agentStatusValue === voiceAgentStatus.ONLINE_WEB && !twilioDeviceIsReady) {
      registerTwilio();
    }
  }, [voiceIntegId, twilioDevice, callProperties?.agentStatusValue]);

  const makeOutboundCall = async (searchValue) => {
    console.log("Twilio-Event-Call :::Outbound call>>>", customerNumber, ">>", orgNumber);
    if (!twilioDevice) {
      console.error("Twilio device is not available.");
      return;
    }

    setSearchValue(searchValue);
    let targetNumber =
      customerNumber && customerNumber.trim() !== "" ? customerNumber : searchValue && searchValue.trim() !== "" ? searchValue : null;

    const phonePattern = /^\+?[0-9\s-]{9,15}$/;
    if (targetNumber !== null && targetNumber !== "" && targetNumber) {
      if (!phonePattern.test(targetNumber)) {
        console.error("Please provide a valid phone number.");
        message.warning("Please provide a valid phone number");
        return;
      }
    }

    if (!targetNumber || !orgNumber) {
      console.error("Missing required parameters: targetNumber or orgNumber.");
      return;
    }
    if (targetNumber && orgNumber) {
      setCallType("outbound");
      setIncomingCallTo(orgNumber);
      const params = {
        To: targetNumber,
        From: orgNumber,
        leftServiceId: appProperties?.leftServiceId,
        callRecordId: recordId,
        callRecordModule: module,
        calledBy: appProperties?.currentUser?.osyncUserId,
      };
      console.log("Twilio-Event-Call ::: Call parameters: ", params);

      const deviceParams = {
        params: params,
      };
      try {
        const call = await twilioDevice.connect(deviceParams);
        setCallParams(call);
        setCallSid(call.parameters);

        call.on("ringing", () => {
          console.log("Twilio-Event-Call ::: Call is ringing...", call.status());
          setCallProperties((prev) => ({
            ...prev,
            disableAgentStatusChange: true,
          }));
          if (callProperties?.loadedSDK) {
            appProperties?.controller?.enableFocusMode(callProperties?.loadedSDK);
          }

          setCallProperties((prev) => ({
            ...prev,
            callStatus: "ringing",
          }));
        });

        call.on("accept", (e) => {
          console.log("Twilio-Event-Call ::: Call accepted...", call.status(), "::: call sid >>>>>>", call.parameters.CallSid);
          handleStatusChange(voiceAgentStatus.ON_CALL);
          setCallProperties((prev) => ({
            ...prev,
            callStatus: "answered",
          }));
        });

        call.on("rejected", () => {
          console.log("Twilio-Event-Call ::: Call rejected...", call.status(), "::: call params >>", call.parameters);
          setCallProperties((prev) => ({
            ...prev,
            callStatus: "save-close",
          }));
          handleStatusChange(voiceAgentStatus.AFTER_CALL_PROCESS);
        });

        call.on("disconnect", () => {
          console.log("Twilio-Event-Call ::: Call disconnected...", call.status(), "::: call params >>", call.parameters);
          // setCallProperties((prev) => ({
          //   ...prev,
          //   callStatus: "save-close",
          // }));
          // handleStatusChange(voiceAgentStatus.AFTER_CALL_PROCESS);
          setCallEnded(true);
        });

        call.on("cancel", () => {
          console.log("Twilio-Event-Call ::: Call canceled...", call.status(), "::: call params >>", call.parameters);
          setCallProperties((prev) => ({
            ...prev,
            callStatus: "save-close",
          }));
        });

        call.on("ignore", () => {
          console.log("Twilio-Event-Call ::: Call ignored...", call.status(), "::: call params >>", call.parameters);
          setCallProperties((prev) => ({
            ...prev,
            callStatus: "save-close",
          }));
        });
      } catch (error) {
        console.error("Error connecting the call:", error);
      }
    } else {
      console.log("Twilio-Event-Call ::: Phone number is not entered.");
      message.warning("Please Enter valid Mobile Number!");
    }
  };

  const handleStatusChange = (value) => {
    return new Promise((resolve, reject) => {
      if (leftServiceId && appProperties?.userId && voiceIntegId) {
        fetchData(
          `voice/${voiceIntegId}/agents/${appProperties.userId}?status=${value}&leftServiceId=${leftServiceId}`,
          "PUT",
          { payload: "" },
          appProperties
        )
          .then((response) => {
            const statusValueResponse = JSON.parse(response);
            const statusValue = statusValueResponse?.data?.voiceAgentStatusEntity?.voiceAgentStatus || voiceAgentStatus.OFFLINE;

            if (statusValue === voiceAgentStatus.ON_CALL || statusValue === voiceAgentStatus.AFTER_CALL_PROCESS) {
              setCallProperties((prev) => ({
                ...prev,
                agentStatusValue: voiceAgentStatus.BUSY,
              }));
            } else {
              setCallProperties((prev) => ({
                ...prev,
                agentStatusValue: statusValue,
              }));
            }

            if (statusValue === voiceAgentStatus.ONLINE_WEB) {
              setCallProperties((prev) => ({
                ...prev,
                callStatus: "idle",
              }));
              setShowOfflineMessageWhileInit(false);
            }
            const token = statusValueResponse?.data?.capToken;
            if (token) {
              setCallProperties((prev) => ({
                ...prev,
                twilioCapToken: token,
              }));
            }
            resolve(statusValueResponse);
          })
          .catch((error) => {
            console.error("Error updating status:", error);
            reject(error);
          });
      } else {
        console.error("Missing leftServiceId or userId.");
      }
    });
  };

  const rejectCall = () => {
    if (callProperties?.loadedSDK) {
      appProperties.controller.disableFocusMode(callProperties?.loadedSDK);
    }
    if (callConnection) {
      if (callConnection?.status() === "pending") {
        setCallProperties((prev) => ({
          ...prev,
          isDialPadVisible: false,
          initiateOutboundCall: false,
          disableAgentStatusChange: false,
          callStatus: "idle",
          incomingCall: false,
        }));
        setCustomerNumber("");
        setCallConnection({});
        setCallConnection({});
        callConnection?.reject();
        callConnection.disconnect();
      } else {
        callConnection.disconnect();
      }
    } else {
      setCallProperties((prev) => ({
        ...prev,
        callStatus: "idle",
        incomingCall: false,
      }));
    }
  };
  const answerCall = () => {
    if (callConnection && Object.keys(callConnection).length > 0) {
      if (callConnection.status() === "pending") {
        handleStatusChange(voiceAgentStatus.ON_CALL)
          .then((response) => {
            setCallProperties((prev) => ({
              ...prev,
              callStatus: "answered",
              incomingCall: false,
            }));
            callConnection.accept();
            appProperties?.controller?.enableFocusMode(callProperties?.loadedSDK);
            setAnswered(true);
          })
          .catch((error) => {
            console.error("Error changing call status:", error);
          });
      } else {
        console.log("Call is not in pending state. Cannot answer.");
      }
    } else {
      console.warn("No valid call connection found.");
    }
  };

  const handleDialPadClose = () => {
    setCallProperties((prev) => ({
      ...prev,
      isDialPadVisible: false,
    }));
    appProperties?.controller?.hideCallNotification(callProperties.loadedSDK);
  };
  const handleSaveAndCloseClick = (notes) => {
    try {
      setSaveAndCloseClicked(true);
      if (callSid || callSid?.CallSid) {
        const payload = {
          callSid: callSid?.CallSid ?? callSid,
          notes: callNotesForm.getFieldValue("callDescription"),
          action: "saveAndClose",
        };

        fetchData(`voice/log?integId=${voiceIntegId}`, "POST", payload, appProperties)
          .then((response) => {
            if (response) {
              setFetchCallLogs(true);
              setCallProperties((prev) => ({
                ...prev,
                isDialPadVisible: false,
                disableAgentStatusChange: false,
                callStatus: "idle",
              }));

              resetCallPopup();

              if (callProperties?.loadedSDK) {
                appProperties.controller.disableFocusMode(callProperties.loadedSDK);
                appProperties.controller.hideCallNotification(callProperties.loadedSDK);
              }
              setSaveAndCloseClicked(false);
            }

            return handleStatusChange(voiceAgentStatus.ONLINE_WEB);
          })
          .catch((error) => {
            console.error("Error during fetch or status change:", error);
            setSaveAndCloseClicked(false);
          });
      } else {
        console.warn("No Call SID provided.");
        setSaveAndCloseClicked(false);
      }
    } catch (error) {
      console.error("Error Updating Log:", error);
      setSaveAndCloseClicked(false);
    }
  };
  const leftServiceName = appProperties?.leftServiceClientDetails?.displayName;
  const hasVoiceIntegration = appProperties?.installedApps?.some((integration) => integration.right.serviceType === "VOICE");

  return (
    <>
      {!isStatic && !path.includes("inbox") && (
        <div className="hsLandlineIcon" onClick={handleDialPadClick} style={{ position: "fixed", zIndex: 60, right: "3%", bottom: "5%" }} />
      )}

      <div
        className={`${isStatic ? "staticDialpad" : `${callProperties?.isDialPadVisible ? "visible" : ""} dialPad`} ${
          openCallDetails ? "" : "overflow-hidden"
        } `}
      >
        <div className="statusBar d-flex align-items-middle overflow-hidden">
          <div className="d-flex align-items-center w-100 justify-content-between">
            <div
              onClick={redirectToCallHistoryPage}
              className="staticIconsSprite smallSize-hellosend-icon cursor-pointer"
              alt={COMPANY_NAME}
            ></div>
            <>
              <div className="d-flex align-items-center justify-content-between w-50">
                <div className="d-flex align-items-center w-100 justify-content-end">
                  <div className="d-flex justify-content-end"></div>
                </div>
                <div className="d-flex align-items-center w-100 justify-content-end">
                  <Row className="d-flex align-items-center w-100 justify-content-between">
                    <Col span={8} className="cursor-pointer" onClick={handleDialPadClose}>
                      <div className={`${callProperties?.disableAgentStatusChange ? "disable-minimize dialCloseIcon" : "dialCloseIcon"}`}></div>
                    </Col>
                    <Col span={16} className="d-flex align-items-center w-100 justify-content-end cursor-pointer">
                      <Dropdown
                        menu={{ items: avatarDropDownMenuItems }}
                        dropdownRender={(menu) => <div className="create-record-link-parent">{menu}</div>}
                        trigger={["click"]}
                      >
                        <Button
                          className="border-none boxShadowRemove  d-flex justify-content-center h-100 p-0"
                          style={{
                            background: "none",
                          }}
                        >
                          <Space className="profileAvatarBg h-100 w-100">
                            <Avatar
                              size="large"
                              className="manageSubscription dialpad-avatar-class"
                              src={appProperties?.userLogo}
                              border="light"
                            >
                              {appProperties?.userLogo}
                            </Avatar>
                            <div
                              style={{
                                backgroundColor: AgentStatusColors[callProperties?.agentStatusValue],
                              }}
                              className="dialpad-agent-status-positioned-circle"
                            ></div>
                          </Space>
                        </Button>
                      </Dropdown>
                    </Col>
                  </Row>
                </div>
              </div>
            </>
          </div>
        </div>
        {openCreateLeadOrContactForm ? (
          <CreateLeadOrContact
            module={module}
            recordId={recordId}
            setOpenCreateLeadOrContactForm={setOpenCreateLeadOrContactForm}
            createModuleName={createModuleName}
            customerNumber={customerNumber}
            searchValue={searchValue}
            setRecordId={setRecordId}
            setCustomerNumber={setCustomerNumber}
            setCreateModuleName={setCreateModuleName}
            setModule={setModule}
            setRecordLink={setRecordLink}
            setLastName={setLastName}
            callSid={callSid?.CallSid ?? callSid}
          />
        ) : (
          // : openCallDetails ? (
          //   <CallHistoryDetails callDetails={callDetails} setOpenCallDetails={setOpenCallDetails} setIdleSelectedMenu={setIdleSelectedMenu} />
          // )
          <>
            {callProperties?.incomingCall ? (
              <IncomingPopUp
                callerImageComponent={
                  <CallPulseImage pulse={true} image={avatar} lastName={lastName} customerNumber={customerNumber} searchValue={searchValue} />
                }
                customerNumber={customerNumber}
                lastName={lastName}
                avatar={avatar}
                hangUpCall={hangUpCall}
                module={module}
                recordId={recordId}
                searchValue={searchValue}
                answered={answered}
                answerCall={answerCall}
                rejectCall={rejectCall}
                handleLastNameClick={handleLastNameClick}
                appProperties={appProperties}
                recordLink={recordLink}
                incomingCallTo={incomingCallTo}
                incomingCall={true}
                skeletonImage={<SkeletonImage />}
                skeletonField={<SkeletonField />}
                callProperties={callProperties}
                customerInfoDetails={
                  <CustomerDetails
                    setRecordId={setRecordId}
                    customerNumber={customerNumber}
                    setCreateModuleName={setCreateModuleName}
                    module={module}
                    recordId={recordId}
                    appProperties={appProperties}
                    recordLink={recordLink}
                    skeletonField={<SkeletonField />}
                    openCreateLeadOrContactForm={openCreateLeadOrContactForm}
                    setOpenCreateLeadOrContactForm={setOpenCreateLeadOrContactForm}
                    searchValue={searchValue}
                  />
                }
              />
            ) : callProperties?.callStatus === "idle" ? (
              // <CallDefaultPage
              //   callStatus={callProperties?.callStatus}
              //   makeOutboundCall={makeOutboundCall}
              //   phoneNumbers={phoneNumbers}
              //   orgNumber = {orgNumber}
              // />

              <>
                {restrictAccess ? (
                  <>
                    <NoAccess
                      hasVoiceIntegration={hasVoiceIntegration}
                      leftServiceName={leftServiceName}
                      handleStatusChange={handleStatusChange}
                      setShowOfflineMessageWhileInit={setShowOfflineMessageWhileInit}
                    />
                  </>
                ) : (
                  <CallRecordSearch
                    setFetchCallLogs={setFetchCallLogs}
                    fetchCallLogs={fetchCallLogs}
                    setOpenCallDetails={setOpenCallDetails}
                    openCallDetails={openCallDetails}
                    callDetails={callDetails}
                    setCallDetails={setCallDetails}
                    orgNumber={orgNumber}
                    setCustomerNumber={setCustomerNumber}
                    makeOutboundCall={makeOutboundCall}
                    phoneNumbers={phoneNumbers}
                    appProperties={appProperties}
                    setLastName={setLastName}
                    setModule={setModule}
                    setRecordId={setRecordId}
                    setAvatar={setAvatar}
                    setRecordLink={setRecordLink}
                    voiceIntegId={voiceIntegId}
                    setOrgNumber={setOrgNumber}
                    showOfflineMessageWhileInit={showOfflineMessageWhileInit}
                    setShowOfflineMessageWhileInit={setShowOfflineMessageWhileInit}
                    handleStatusChange={handleStatusChange}
                    isVoiceUser={isVoiceUser}
                  />
                )}
              </>
            ) : callProperties?.callStatus === "ringing" ? (
              <IncomingPopUp
                callerImageComponent={
                  <CallPulseImage pulse={true} image={avatar} lastName={lastName} customerNumber={customerNumber} searchValue={searchValue} />
                }
                customerNumber={customerNumber}
                avatar={avatar}
                endCall={endCall}
                searchValue={searchValue}
                hangUpCall={hangUpCall}
                module={module}
                appProperties={appProperties}
                recordLink={recordLink}
                lastName={lastName}
                recordId={recordId}
                incomingCallTo={incomingCallTo}
                incomingCall={false}
                skeletonImage={<SkeletonImage />}
                skeletonField={<SkeletonField />}
                callProperties={callProperties}
                customerInfoDetails={
                  <CustomerDetails
                    setRecordId={setRecordId}
                    customerNumber={customerNumber}
                    setCreateModuleName={setCreateModuleName}
                    module={module}
                    recordId={recordId}
                    appProperties={appProperties}
                    recordLink={recordLink}
                    skeletonField={<SkeletonField />}
                    openCreateLeadOrContactForm={openCreateLeadOrContactForm}
                    setOpenCreateLeadOrContactForm={setOpenCreateLeadOrContactForm}
                    searchValue={searchValue}
                  />
                }
              />
            ) : callProperties?.callStatus === "answered" ? (
              <CallAnswered
                callNotesFormName={callNotesForm}
                callerImageComponent={
                  <CallPulseImage pulse={false} image={avatar} lastName={lastName} customerNumber={customerNumber} searchValue={searchValue} />
                }
                lastName={lastName}
                avatar={avatar}
                recordId={recordId}
                recordLink={recordLink}
                setTotalCallDuration={setTotalCallDuration}
                totalCallDuration={totalCallDuration}
                searchValue={searchValue}
                endCall={endCall}
                module={module}
                setCallEnded={setCallEnded}
                callEnded={callEnded}
                callNotes={callNotes}
                setCallNotes={setCallNotes}
                callObject={callParams}
                device={twilioDevice}
                callStatus={callProperties?.callStatus}
                appProperties={appProperties}
                customerNumber={customerNumber}
                skeletonImage={<SkeletonImage />}
                skeletonField={<SkeletonField />}
                callProperties={callProperties}
                customerInfoDetails={
                  <CustomerDetails
                    setRecordId={setRecordId}
                    customerNumber={customerNumber}
                    setCreateModuleName={setCreateModuleName}
                    module={module}
                    recordId={recordId}
                    appProperties={appProperties}
                    recordLink={recordLink}
                    skeletonField={<SkeletonField />}
                    openCreateLeadOrContactForm={openCreateLeadOrContactForm}
                    setOpenCreateLeadOrContactForm={setOpenCreateLeadOrContactForm}
                    searchValue={searchValue}
                  />
                }
              />
            ) : callProperties?.callStatus === "save-close" ? (
              <CallSaveAndClose
                callerImageComponent={
                  <CallPulseImage
                    pulse={false}
                    image={avatar}
                    lastName={lastName}
                    customerNumber={customerNumber}
                    SkeletonImage={SkeletonImage}
                    SkeletonField={SkeletonField}
                    searchValue={searchValue}
                  />
                }
                callNotesFormName={callNotesForm}
                searchValue={searchValue}
                customerNumber={customerNumber}
                avatar={avatar}
                module={module}
                callNotes={callNotes}
                setCallNotes={setCallNotes}
                saveAndCloseClicked={saveAndCloseClicked}
                handleSaveAndCloseClick={handleSaveAndCloseClick}
                totalCallDuration={totalCallDuration}
                lastName={lastName}
                recordId={recordId}
                recordLink={recordLink}
                appProperties={appProperties}
                skeletonImage={<SkeletonImage />}
                skeletonField={<SkeletonField />}
                callProperties={callProperties}
                customerInfoDetails={
                  <CustomerDetails
                    setRecordId={setRecordId}
                    customerNumber={customerNumber}
                    setCreateModuleName={setCreateModuleName}
                    module={module}
                    recordId={recordId}
                    appProperties={appProperties}
                    recordLink={recordLink}
                    skeletonField={<SkeletonField />}
                    openCreateLeadOrContactForm={openCreateLeadOrContactForm}
                    setOpenCreateLeadOrContactForm={setOpenCreateLeadOrContactForm}
                    searchValue={searchValue}
                  />
                }
              />
            ) : null}

            {upgradeAlertPopup && <UpgradeAlertPopup upgradeAlertPopup={upgradeAlertPopup} setUpgradeAlertPopup={setUpgradeAlertPopup} />}
          </>
        )}
      </div>
    </>
  );
};
export default Dialpad;

export const CustomerDetails = (props) => {
  let {
    customerNumber,
    setRecordId,
    module,
    setCreateModuleName,
    recordId,
    appProperties,
    recordLink,
    skeletonField,
    openCreateLeadOrContactForm,
    setOpenCreateLeadOrContactForm,
    searchValue,
  } = props;
  const [callProperties, setCallProperties] = useVoiceContext();

  const openCustomerPage = () => {
    if ((recordId, module)) {
      appProperties.controller.openRecord(callProperties?.loadedSDK, recordId, module, recordLink);
    }
  };
  const createRecordPage = (recordModule) => {
    setCreateModuleName(recordModule);
    if ((recordId, recordModule)) {
      appProperties.controller
        .createRecord(callProperties?.loadedSDK, recordModule, openCreateLeadOrContactForm, setOpenCreateLeadOrContactForm)
        .then(function () {
          appProperties.controller.populateRecord(callProperties?.loadedSDK, recordId, recordModule, customerNumber);
        });
    }
  };

  const createLeadOrContactActionMenu = [
    {
      key: "1",
      label: (
        <div
          className="hs-fs-12"
          onClick={() => {
            createRecordPage("leads");
          }}
        >
          Create new lead
        </div>
      ),
    },
    {
      key: "2",
      label: (
        <div
          className="hs-fs-12"
          onClick={() => {
            createRecordPage("contacts");
          }}
        >
          Create new contact
        </div>
      ),
    },
  ];
  return (
    <>
      {recordId || recordId === "" ? (
        <>
          {recordId !== "" ? (
            <div className="view-record-link-md " onClick={() => openCustomerPage(appProperties, recordId, module, recordLink)}>
              <Row>
                <Col>
                  <div className="d-flex flex-row hs-fs-12 hs-fw-400">
                    <>View {module} details</>
                    <div className="call-action-icons view-contact-details-forward-icon"></div>
                  </div>
                </Col>
              </Row>
            </div>
          ) : (
            <div className="hs-fs-14 text-white hs-fw-400 hs-border-16 create-record-link">
              <Row className="hs-fs-12">
                <Col>
                  <Dropdown
                    menu={{
                      items: createLeadOrContactActionMenu,
                    }}
                    dropdownRender={(menu) => <div className="create-record-link-parent">{menu}</div>}
                    trigger={["click"]}
                  >
                    <div className="d-flex flex-row justify-content-center align-items-middle">
                      <div className="create-record-plus-icon call-action-icons cursor-pointer" />
                      <div className="hs-fw-400 hs-fs-12 d-flex align-items-center">Create lead or contact</div>
                      <div className="create-record-dropdown-icon call-action-icons cursor-pointer" />
                    </div>
                  </Dropdown>
                </Col>
              </Row>
            </div>
          )}
        </>
      ) : (
        <>
          {" "}
          <div
            className={` ${
              callProperties?.callStatus === "save-close" || callProperties?.callStatus === "answered"
                ? " d-flex justify-content-start ps-3"
                : "  d-flex justify-content-center "
            }`}
          >
            {skeletonField}
          </div>{" "}
        </>
      )}
    </>
  );
};
export const SkeletonField = () => {
  const [callProperties, setCallProperties] = useVoiceContext();
  return (
    <div
      className={`w-50 d-flex justify-content-center  align-items-center ${
        callProperties?.callStatus === "save-close" || callProperties?.callStatus === "answered" ? "" : ""
      }`}
    >
      <Skeleton.Input
        active={true}
        size={"small"}
        block={true}
        style={{ backgroundColor: "rgba(255, 255, 255, 0.1)" }}
        rootClassName="d-flex align-items-center justify-content-center"
      />
    </div>
  );
};
export const SkeletonImage = () => {
  return (
    <div className="d-flex justify-content-center align-items-center">
      <Skeleton.Image active={true} size={"small"} block={true} style={{ backgroundColor: "rgba(255, 255, 255, 0.1)", borderRadius: "50%" }} />
    </div>
  );
};

export const CallPulseImage = (props) => {
  let { pulse, image, lastName, customerNumber, searchValue } = props;

  const [callerImageObj, setCallerImageObj] = useState({});
  useEffect(() => {
    if (lastName || customerNumber || searchValue) {
      let imageObj = getDisplayNameOfCustomer(lastName, customerNumber, searchValue);
      if (imageObj) {
        setCallerImageObj(imageObj);
      }
    }
  }, [lastName, customerNumber, searchValue]);

  const getDisplayNameOfCustomer = (customerName, customerNumber, searchValue) => {
    let isPhoneNumber = false;
    if (customerNumber) {
      isPhoneNumber = true;
    }
    let iconLetter = customerName
      ? customerName?.slice(0, 1)?.toUpperCase()
      : isPhoneNumber || searchValue
      ? "#"
      : customerName?.slice(0, 1)?.toUpperCase();

    return {
      displayName: customerName,
      iconLetter: iconLetter,
      isPhoneNumber: isPhoneNumber,
    };
  };

  return (
    <>
      <div className="wrapper d-flex align-items-center justify-content-center">
        <div className={`${pulse ? "pulse" : ""} circle`}></div>
        <div className={`${pulse ? "" : "avatar-big-round"} circle d-flex align-items-center justify-content-center`}>
          {image ? (
            <Avatar rootClassName="w-100 h-100" src={image} />
          ) : callerImageObj?.iconLetter ? (
            <span className="hs-color-dark-orange hs-fw-600 hs-fs-18">{callerImageObj?.iconLetter}</span>
          ) : (
            ""
          )}
        </div>
      </div>
    </>
  );
};
